exports.components = {
  "component---src-sites-type-background-dobre-wiesci-templates-add-domain-tsx": () => import("./../../../src/sites/typeBackground/dobre-wiesci/templates/AddDomain.tsx" /* webpackChunkName: "component---src-sites-type-background-dobre-wiesci-templates-add-domain-tsx" */),
  "component---src-sites-type-background-dobre-wiesci-templates-article-tsx": () => import("./../../../src/sites/typeBackground/dobre-wiesci/templates/Article.tsx" /* webpackChunkName: "component---src-sites-type-background-dobre-wiesci-templates-article-tsx" */),
  "component---src-sites-type-background-dobre-wiesci-templates-contact-tsx": () => import("./../../../src/sites/typeBackground/dobre-wiesci/templates/Contact.tsx" /* webpackChunkName: "component---src-sites-type-background-dobre-wiesci-templates-contact-tsx" */),
  "component---src-sites-type-background-dobre-wiesci-templates-home-tsx": () => import("./../../../src/sites/typeBackground/dobre-wiesci/templates/Home.tsx" /* webpackChunkName: "component---src-sites-type-background-dobre-wiesci-templates-home-tsx" */),
  "component---src-sites-type-background-dobre-wiesci-templates-not-found-tsx": () => import("./../../../src/sites/typeBackground/dobre-wiesci/templates/NotFound.tsx" /* webpackChunkName: "component---src-sites-type-background-dobre-wiesci-templates-not-found-tsx" */),
  "component---src-sites-type-background-dobre-wiesci-templates-search-tsx": () => import("./../../../src/sites/typeBackground/dobre-wiesci/templates/Search.tsx" /* webpackChunkName: "component---src-sites-type-background-dobre-wiesci-templates-search-tsx" */),
  "component---src-sites-type-background-dobre-wiesci-templates-terms-tsx": () => import("./../../../src/sites/typeBackground/dobre-wiesci/templates/Terms.tsx" /* webpackChunkName: "component---src-sites-type-background-dobre-wiesci-templates-terms-tsx" */)
}

